import Marionette from 'backbone.marionette';
import ReaderAdminEditView from 'views/newsroom/readerAdminEditView';
import readerConfigurationsInstance from 'collections/newsroom/configurationsInstance';
import I18n from 'i18n';
import ModalView from 'views/components/modalView';
import Notificator from 'libraries/notificator';
import Category from 'models/newsroom/category';
import _ from 'underscore';
import DateFormatter from 'libraries/dateFormatter';
import moment from 'moment';
import EditableTitleView from 'views/components/editableReactTitleView';
import ReactWrapper from "views/marionetteReactWrapper";
import * as React from 'react'
import { SenderDefaultValues } from 'views/newsroom/readerNotificationConfigurationSenderFormView';

class ReaderAdminEditController extends Marionette.Controller {
    initialize(options) {
        this.configurationId = options.configurationId;
        this.configuration = readerConfigurationsInstance.get(options.configurationId);
        this.configuration.fetch();
        this.view = new ReaderAdminEditView({ model: this.configuration });
        this._registerListeners();
    }

    _notificationConfigurationProps() {
        return _.extend(
            {},
            this._categoryProps(),
            {
                sortableEnabled: false,
                sortableListItems: this.configuration.notification_configurations.models,
                header: I18n.t('webapp.reader_admin.notifications.heading'),
                addObjectText: I18n.t('webapp.reader_admin.edit.add_notification_configuration'),
                onObjectClick: (object) => {
                    this.trigger('reader-notification-configuration-selected', object);
                    this.showNotificationConfigurations({
                        selectedObject: object
                    });
                },
                onObjectClose: () => {
                    this.trigger('reader-notification-configuration-closed');
                    this.showNotificationConfigurations({
                        selectedObject: null
                    });
                },
                listItemObjectNamePostfix: (nConfig) => {
                    const triggers = nConfig.triggers.models;
                    const schedules = nConfig.schedules.models;
                    const triggerTypes = _.map(triggers, (trigger) => {
                        return trigger.get('trigger_type');
                    });
                    const uniqTriggerTypes = _.uniq(triggerTypes);

                    if (_.isEmpty(uniqTriggerTypes) && _.isEmpty(schedules)) {
                        return '';
                    } else if (uniqTriggerTypes.length === 1 && _.isEmpty(schedules)) {
                        switch(uniqTriggerTypes[0]) {
                            case "new_results":
                                return I18n.t('webapp.reader_admin.edit.notification_configuration.trigger.new_results.button_description');
                            default:
                                return I18n.t('webapp.reader_admin.edit.notification_configuration.trigger.sync_with_publish_time.button_description');
                        }
                    } else if (_.isEmpty(uniqTriggerTypes) && schedules.length === 1) {
                        const schedule = schedules[0];
                        const localizedWeekdays = moment.weekdaysShort();
                        const weekdays = _.map(schedule.get('weekdays'), (weekdayIndex) => {
                            return localizedWeekdays[weekdayIndex];
                        });
                        let time = schedule.get('time')
                        return I18n.t(
                            'webapp.reader_admin.edit.notification_configuration.trigger.by_schedule.button_description',
                            {
                              trigger_time: DateFormatter.formatTime(
                                  moment.utc(time, "YYYY/MM/DD HH:mm:ss Z")
                              ),
                              trigger_weekdays: weekdays.join(', ')
                            }
                        );
                    } else {
                        return I18n.t('webapp.reader_admin.edit.notification_configuration.trigger.button_with_multiple_triggers');
                    }
                },
                onSortableUpdate: () => {},
                onAddNewObject: () => {
                  const nConfig = this.configuration.notification_configurations.create({
                      reader_configuration_id: this.configuration.get('id'),
                      name: I18n.t('webapp.reader_admin.edit.notification_configuration.default_name'),
                      sender_email: SenderDefaultValues.email(),
                      sender_name: SenderDefaultValues.name()
                  });
                  this.trigger('reader-notification-configuration-selected', nConfig);
                }
            }
        );
    }

    showNotificationConfigurations(props) {
        const fullProps = _.extend(
            {}, this._notificationConfigurationProps(), props
        );
        this.view.showNotificationConfigurations(fullProps);
    }

    deselectNotificationConfigurations() {
        this.showNotificationConfigurations({ selectedObject: null });
    }

    _categoryProps() {
        return {
            sortableEnabled: true,
            selectedObject: null,
            sortableListItems: this.configuration.categories.models,
            sortableGroup: 'ReaderCategories',
            listItemObjectId: 'id',
            listItemPosition: 'position',
            listItemParentId: 'reader_configuration_id',
            listItemName: 'name',
            listItemObjectNamePostfix: () => { return ''; },
            header: I18n.t("webapp.reader_admin.edit.categories"),
            addObjectText: I18n.t("webapp.reader_admin.edit.add_category"),
            onObjectClick: (object) => {
                this.trigger('category-selected', object);
                this.showCategories({ selectedObject: object });
            },
            onObjectClose: () => {
                this.trigger('category-closed');
                this.showCategories({ selectedObject: null });
            },
            onSortableUpdate: (sortableUpdateEvent) => {
                const readerCategory = this.configuration.categories.get(
                    sortableUpdateEvent.item.dataset.objectId
                );
                const replacedCategory = this.configuration.categories.at(
                    sortableUpdateEvent.newIndex
                );
                const newPosition = replacedCategory.get('position');
                readerCategory.set('position', newPosition);
                readerCategory.save().done(() => {
                    this.configuration.categories.fetch();
                });
            },
            onAddNewObject: () => {
                const categories = this.configuration.categories;
                let lastPosition = 1;

                if (!_.isEmpty(categories.models)) {
                    lastPosition = categories.sort().last().get('position') + 1;
                }

                let category = new Category({
                    reader_configuration_id: this.configuration.get('id'),
                    name: I18n.t('webapp.reader_admin.edit.category.default_name'),
                    position: lastPosition,
                    only_important_results: false
                });
                category.save().done(() => {
                    this.showCategories();
                });
                this.configuration.categories.add(category);
                this.trigger('category-selected', category);
            }
        };
    }

    showCategories(props) {
        const fullProps = _.extend({}, this._categoryProps(), props);
        this.view.showCategories(fullProps);
    }

    deselectCategories() {
        this.showCategories({ selectedObject: null });
    }

    _onRender(params) {
      const props = {
        title: this.configuration.get('title'),
        titleFieldName: 'title',
        model: this.configuration
      }
      this.editableTitleView = new ReactWrapper({
        getComponent: () => {
          return <EditableTitleView {...props}/>;
        }
      });
      this.view.showEditableTitle(this.editableTitleView);
      this.showCategories();
      this.showNotificationConfigurations(params);
      this.showInsights();
    }

    _registerListeners() {
        this.listenTo(this.view, 'sendTokenModal', this._showSendTokenModal);
        this.listenTo(this.view,
                      'configuration:delete',
                      this.deleteConfiguration);
        this.listenTo(this.view, 'render', () => {
            this._onRender();
        });

        this.listenTo(this.view, 'showPresentationOptions',
            this.showPresentationOptions);

        this.listenTo(this.view, 'click:archivePattern', () => {
            this.trigger('showArchivePattern');
        });

        this.listenTo(this.view, 'click:refreshPattern', () => {
            this.trigger('showRefreshPattern');
        });

        this.listenTo(this.view, 'click:emailSettings', () => {
            this.trigger('showEmailSettings');
        });

        this.listenTo(this.view, 'click:audience', () => {
            this.trigger('showAudience');
        });
        this.listenTo(this.view, 'click:audiencePrivacy', () => {
            this.trigger('showAudiencePrivacy');
        });

        const registerListenersForSchedules = (nConfig) => {
            this.listenTo(nConfig.schedules, 'add remove', (schedule) => {
                this.listenTo(schedule, 'change', () => {
                    this.showNotificationConfigurations({ selectedObject: nConfig });
                });
                this.showNotificationConfigurations({ selectedObject: nConfig });
            });
        };
        const registerListenersForTriggers = (nConfig) => {
            this.listenTo(nConfig.triggers, 'add remove', () => {
                this.showNotificationConfigurations({ selectedObject: nConfig });
            });
            this.showNotificationConfigurations({ selectedObject: nConfig });
        };
        const notificationConfigurations =
            this.configuration.notification_configurations;
        notificationConfigurations.each((nConfig) => {
            registerListenersForSchedules(nConfig);
            registerListenersForTriggers(nConfig);
        });
        this.listenTo(notificationConfigurations, 'add', (nConfig) => {
            registerListenersForSchedules(nConfig);
            registerListenersForTriggers(nConfig);
        });
        this.listenTo(notificationConfigurations, 'change', (nConfig) => {
            this.showNotificationConfigurations({ selectedObject: nConfig });
        });
        this.listenTo(notificationConfigurations, 'remove', (nConfig) => {
            nConfig.stopListening(nConfig.schedules);
            nConfig.stopListening(nConfig.triggers);
            this.showNotificationConfigurations();
        });

        this.listenTo(this.configuration.categories, 'add', (category) => {
                this.showCategories({ selectedObject: category });
            }
        );
        this.listenTo(this.configuration.categories, 'remove', () => {
                this.showCategories();
            }
        );
        this.listenTo(this.configuration.categories, 'change', (cat, action) => {
            if (!_.isEmpty(action) && !action.validate && cat.changed.position) {
                this.configuration.categories.sort();
                this.showCategories();
            } else if (cat.changed.name) {
                this.showCategories({ selectedObject: cat });
            }
        });
    }

    deleteConfiguration() {
        let _this = this;
        this.trigger('configuration:deleteStarted');
        this.configuration.destroy({
            success: function() {
                _this.trigger('configuration:deleted');
            },

            error: function() {
                Notificator.showErrorNotification(
                    I18n.t('webapp.notifications.error.not_saved')
                );
            }
        });
    }

    _showSendTokenModal() {
        this.modalView = new ModalView({
            confirmation: I18n.t('webapp.reader_admin.edit.send_token_modal.title'),
            warning: I18n.t('webapp.reader_admin.edit.send_token_modal.body'),
            confirmationLabel: I18n.t('webapp.reader_admin.edit.send_token_modal.confirmation'),
            cancelLabel: I18n.t('webapp.reader_admin.edit.send_token_modal.cancel'),
            positiveButton: true
        });
        this.listenTo(
            this.modalView,
            'modal:confirmed',
            this.sendToken
        );
        this.view.showSendTokenModal(this.modalView);
        this.modalView.showModal();
    }

    sendToken() {
        this.configuration.sendToken({
            success: function() {
                Notificator.showNotification(
                    I18n.t('webapp.notifications.reader.token_email_successful')
                );
            },

            error: function() {
                Notificator.showErrorNotification(
                    I18n.t('webapp.notifications.error.not_saved')
                );
            }
        });
    }

    showPresentationOptions() {
        this.trigger('showPresentationOptions');
    }

    showInsights() {
        this.view.showMediaInsights();
    }

}

export default ReaderAdminEditController;
